export default [
    {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
        resource: 'dashboard',
        action: 'index',
    },
    {
        title: 'roles.manage',
        // icon: 'UsersIcon',
        icon: 'BookOpenIcon',
        children: [
            {
                title: 'roles.roles.page-title.index',
                route: 'roles',
                resource: 'roles',
                action: 'index'
            },
            {
                title: 'roles.associated-roles.page-title.index',
                route: 'associated-roles',
                resource: 'associated-roles',
                action: 'index',
            },
        ]
    },
    {
        title: 'Users',
        route: 'users',
        icon: 'UserIcon',
        resource: 'users',
        action: 'read',
    },
    {
        title: 'admin.category.manageCategories',
        route: 'categories-management',
        icon: 'LayersIcon',
        resource: 'categories',
        action: 'management',
    },
    {
        title: 'user.contracts.contracts',
        route: 'contracts',
        icon: 'FileTextIcon',
        resource: 'contracts',
        action: 'index',
    },
    {
        title: 'user.settings.settings',
        route: 'settings',
        icon: 'SettingsIcon',
        resource: 'user-dashboard',
        action: 'index',
    },
]
